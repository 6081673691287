import React from 'react';
import './css/notablog.css';
import './css/theme.css';
import MoviesImage from '../images/Movies.jpg'; // Adjust the import path as needed

const Watch = () => {
    return (
        <div className="Page">
            {/* Main Content */}
            <header className="Header">
                <div className="Header__Cover"></div>
                <div className="Header__Spacer"></div>
                <h1 className="Header__Title">What I Watched</h1>
            </header>
            <article className="ArticleList">
                <article className="Article">
                    <h2 className="Article__Title">My Films on Letterboxd</h2>
                    <a href="https://letterboxd.com/Jingan/films/" target="_blank" rel="noopener noreferrer">
                        <img src={MoviesImage} alt="My Letterboxd Films" style={{ width: '100%', height: 'auto' }} />
                    </a>
                </article>
            </article>
        </div>
    );
}

export default Watch;
