import React from 'react';
import './css/notablog.css';
import './css/theme.css';

const Podcast = () => {
    const podcasts = [
        {
            title: "PG Essays (Paul Graham)",
            url: "https://open.spotify.com/embed/episode/3TKqGjtXQXAdRJVeWXRwgu?utm_source=generator"
        },
        {
            title: "Y Combinator Startup Podcast",
            url: "https://open.spotify.com/embed/episode/2pULe2PPMnwdZ9ps3wGIDa/video?utm_source=generator"
        },
        {
            title: "Taiwanren: Steve Chen, Youtube",
            url: "https://open.spotify.com/embed/show/7f1RwB5RMO0AQoMPhycdb7?utm_source=generator"
        },
        {
            title: "股癌",
            url: "https://open.spotify.com/embed/episode/4pH7hd8ASmRUnYgSpdJqVv?utm_source=generator"
        },
        {
            title: "科技解密Tech Action",
            url: "https://open.spotify.com/embed/episode/4UjF8LZtk0w7fTTZDMuw0j?utm_source=generator"
        },
        {
            title: "跳脫Do式圈",
            url: "https://open.spotify.com/embed/episode/3kOzp1K6if2L9NCUiWjK05?utm_source=generator"
        },
        {
            title: "Acquired",
            url: "https://open.spotify.com/embed/episode/5qDmPoFUEUNenkztRQpY4D?utm_source=generator"
        },
        {
            title: "The Social Radars",
            url: "https://open.spotify.com/embed/episode/5ejxWaxdzeXPNYACfjuUrD?utm_source=generator"
        }
    ];

    return (
        <div className="Page">
            {/* Main Content */}
            <header className="Header">
                <div className="Header__Cover"></div>
                <div className="Header__Spacer"></div>
                <h1 className="Header__Title">Podcast</h1>
            </header>
            <article className="ArticleList">
                {podcasts.map((podcast, index) => (
                    <article className="Article" key={index}>
                        <h2 className="Article__Title">{podcast.title}</h2>
                        <iframe
                            src={podcast.url}
                            title={podcast.title}
                            width="100%"
                            height="352"
                            frameBorder="0"
                            allowfullscreen=""
                            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                            loading="lazy"
                        ></iframe>
                    </article>
                ))}
            </article>
        </div>
    );
}

export default Podcast;
