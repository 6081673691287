import React from 'react';
import './css/notablog.css';
import './css/theme.css';

const Travel = () => {
    return (
        <div className="Page">
            {/* Main Content */}
            <header className="Header">
                <div className="Header__Cover"></div>
                <div className="Header__Spacer"></div>
                <h1 className="Header__Title">Travels</h1>
            </header>
            <article className="ArticleList">
                <article className="Article">
                    <h2 className="Article__Title">Travel Map</h2>
                    <iframe src="?" width="640" height="480" title="Travel Map"></iframe>
                </article>
            </article>
        </div>
    );
}

export default Travel;
