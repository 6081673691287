import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { faInstagram, faLinkedin, faMedium, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './css/notablog.css';
import './css/theme.css';


const Home = () => {
    // State to manage the current language
    const [language, setLanguage] = useState('English');

    // Helper function to import all markdown files from a given context
    const importAll = (r) => r.keys().map((fileName) => fileName.replace('./', ''));

    const blogChineseFiles = importAll(require.context('../articles/Featured_Chinese', false, /\.md$/));
    const blogEnglishFiles = importAll(require.context('../articles/Featured_English', false, /\.md$/));

    // Categorize posts
    const posts = [
        { category: 'Featured_Chinese', posts: blogChineseFiles },
        { category: 'Featured_English', posts: blogEnglishFiles },
    ];

    // Filter posts based on the selected language
    const filteredPosts = posts.filter(post => (language === 'English' && post.category === 'Featured_English') || (language === 'Chinese' && post.category === 'Featured_Chinese'));

    return (
        <div className="Page">
            <header className="Header">
                <div className="Header__Cover"></div>
                <div className="Header__Spacer"></div>
                <h1 className="Header__Title">Brandon = Chi-Kang Pai</h1>
            </header>
            <article className="ArticleList">
                <div className='Article'>
                    <h3 className='Article__Desc'>
                        Hi everyone, I'm CK, and welcome to my blog.
                        Here, I'll be sharing my thoughts on life and the technology
                        I'm exploring. I studied Electrical Engineering at National Taiwan
                        University for my undergraduate degree, spent a year in software
                        engineering, and am now pursuing a PhD in Chemical Physics at Harvard.
                        My interests span startups, science, movies, books, and much more!
                        Feel free to reach out if you'd like to chat.
                    </h3>
                    <div className="social-icons">
                        <a href="https://www.linkedin.com/in/chi-kang-pai-5231151a3/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                        <a href="https://twitter.com/bRandom_1105" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faTwitter} />
                        </a>
                        <a href="https://medium.com/@brandonpiii" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faMedium} />
                        </a>
                        <a href="https://www.instagram.com/brandonpiii/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                    </div>
                </div>
                <div>
                    <a className='Article__Title' href="https://brandonpai.net"> Visit my blog website for more articles!!!! </a>
                </div>
                {filteredPosts.map((section) => (
                    <div className="Article" key={section.category}>
                        <h2>Featured Articles</h2>
                        <h3 onClick={() => setLanguage(language === 'English' ? 'Chinese' : 'English')}>
                            {language === 'English' ? '看中文版' : 'See English Articles'}
                        </h3>
                        <ul>
                            {section.posts.map((post) => (
                                <li className='Article__Desc' key={post}>
                                    <Link to={`/articles/${section.category}/${post}`}>
                                        {post.replace(/-/g, ' ').replace('.md', '')}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </article>
        </div>
    );
};

export default Home;