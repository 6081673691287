import React from 'react';
import './css/notablog.css';
import './css/theme.css';

// TODO: Add the images for books, and show some essays.
const Read = () => {
    return (
        <div className="Page">
            <header class="Header">
                <div class="Header__Cover">
                </div>

                <div class="Header__Spacer ">
                </div>
                <h1 class="Header__Title">Readings</h1>
            </header>
            <article class="ArticleList">
                <article class="Article">
                    <h2 class="Article__Title">
                        About Love
                    </h2>
                    <h3 class="Article__Desc">
                        The Art of Loving.
                    </h3>

                    <h2 class="Article__Title">
                        How to think and work
                    </h2>
                    <h3 class="Article__Desc">
                        Clean Code: A Handbook of Agile Software Craftsmanship
                    </h3>
                    <h3 class="Article__Desc">
                        窮查理的普通知識
                    </h3>
                    <h3 class="Article__Desc">
                        Wanting: The Power of Mimetic Desire in Everyday Life
                    </h3>
                    <h3 class="Article__Desc">
                        卡片盒筆記法
                    </h3>
                    <h3 class="Article__Desc">
                        Outliers: The Story of Success
                    </h3>
                    <h2 class="Article__Title">

                    </h2>

                    <h2 class="Article__Title">
                        Articles
                    </h2>
                    <h3 class="Article__Desc">
                        How to do great work.
                    </h3>
                    <h3 class="Article__Desc">
                        How to get startup ideas
                    </h3>
                    <h2 class="Article__Title">
                        Interesting lives of interesting people
                    </h2>
                    <h3 class="Article__Desc">
                        Born a Crime
                    </h3>
                    <h3 class="Article__Desc">
                        GreenLight
                    </h3>

                    <h2 class="Article__Title">
                        Businesses and Technology
                    </h2>
                    <h3 class="Article__Desc">
                        No Filter
                    </h3>
                    <h3 class="Article__Desc">
                        Chip War
                    </h3>
                    <h3>

                    </h3>

                    <h2 class="Article__Title">
                        Novels
                    </h2>
                    <h3 class="Article__Desc">
                        Little Prince
                    </h3>
                    <h3 class="Article__Desc">
                        聽風的歌
                    </h3>

                    <h2 class="Article__Title">
                        Design
                    </h2>
                    <h3 class="Article__Desc">
                        Designing Your Life
                    </h3>
                    <h3 class="Article__Desc">
                        Creative Acts for Curious People: How to Think, Create, and Lead in Unconventional Ways
                    </h3>



                    <div class="DateTagBar">


                    </div>
                </article>
            </article>
        </div>
    );
}

export default Read;