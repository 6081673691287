import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import './css/notablog.css';
import './css/theme.css';

const Post = () => {
    const { category, postName } = useParams();
    const [content, setContent] = useState('');

    useEffect(() => {
        import(`../articles/${category}/${postName}`)
            .then((res) => {
                fetch(res.default)
                    .then((response) => response.text())
                    .then((text) => setContent(text));
            })
            .catch((err) => console.error(err));
    }, [category, postName]);

    return (
        <div className='Page'>
            <header className="Header">
                <div className="Header__Cover"></div>
                <div className="Header__Spacer"></div>
            </header>
            <article className="Article__Post">
                <ReactMarkdown>{content}</ReactMarkdown>
            </article>
        </div>
    );
};

export default Post;