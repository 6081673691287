import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SideBar from './components/SideBar';
import Home from './components/Home';
import About from './components/About';
import Blogs from './components/Blogs';
import Read from './components/Read';
import Music from './components/Music';
import Podcast from './components/Podcast';
import Watch from './components/Watch';
import Travel from './components/Travel';
import Post from './components/Post';
import './App.css';

function App() {
  return (
    <Router>
      <div>
        <SideBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Blogs" element={<Blogs />} />
          <Route path="/Read" element={<Read />} />
          <Route path="/Music" element={<Music />} />
          <Route path="/Podcast" element={<Podcast />} />
          <Route path="/Watch" element={<Watch />} />
          <Route path="/Travel" element={<Travel />} />
          <Route path="/articles/:category/:postName" element={<Post />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;