import React from 'react';
import './css/notablog.css';
import './css/theme.css';

const Music = () => {
    const musicList = [
        {
            title: "Succession, by Nicholas Britell",
            url: "https://open.spotify.com/embed/playlist/3w11vUckYm0Np28KFfG4tv?utm_source=generator"
        },
        {
            title: "Laufey",
            url: "https://open.spotify.com/embed/artist/7gW0r5CkdEUMm42w9XpyZO?utm_source=generator"
        },
        {
            title: "Stephen Sanchez",
            url: "https://open.spotify.com/embed/artist/5XKFrudbV4IiuE5WuTPRmT?utm_source=generator"
        }
    ];

    return (
        <div className="Page">
            {/* Main Content */}
            <header className="Header">
                <div className="Header__Cover"></div>
                <div className="Header__Spacer"></div>
                <h1 className="Header__Title">Music</h1>
            </header>
            <article className="ArticleList">
                {musicList.map((music, index) => (
                    <article className="Article" key={index}>
                        <h2 className="Article__Title">{music.title}</h2>
                        <iframe
                            src={music.url}
                            title={music.title}
                            width="100%"
                            height="352"
                            frameBorder="0"
                            allowFullScreen=""
                            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                            loading="lazy"
                        ></iframe>
                    </article>
                ))}
            </article>
        </div>
    );
}

export default Music;
