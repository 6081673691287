import React from 'react';
import { Link } from 'react-router-dom';
import './css/notablog.css';
import './css/theme.css';

const Blogs = () => {
    // Helper function to import all markdown files from a given context
    const importAll = (r) => r.keys().map((fileName) => fileName.replace('./', ''));

    // Import files from different blog categories
    const blogThinkFiles = importAll(require.context('../articles/Think', false, /\.md$/));
    const blogLifeFiles = importAll(require.context('../articles/Life', false, /\.md$/));
    const blogTechFiles = importAll(require.context('../articles/Tech', false, /\.md$/));

    // Categorize posts
    const posts = [
        { category: 'Think', posts: blogThinkFiles, icon: 'bx bxl-brain' },
        { category: 'Life', posts: blogLifeFiles, icon: 'bx bxl-heart' },
        { category: 'Tech', posts: blogTechFiles, icon: 'bx bxl-code-alt' },
    ];

    return (
        <div className="Page">
            <header className="Header">
                <div className="Header__Cover"></div>
                <div className="Header__Spacer"></div>
                <h1 className="Header__Title">Blogs</h1>
            </header>
            <article className="ArticleList">
                <div className='Article'>
                    <a className='Article__Title' href="https://brandonpai.net"> Visit my blog website for more articles!!!! </a>
                </div>
                {
                    posts.map((section) => (
                        <div className="Article" key={section.category}>
                            <h2><i className={section.icon}></i> {section.category}</h2>
                            <ul>
                                {section.posts.map((post) => (
                                    <li className='Article__Desc' key={post}>
                                        <Link to={`/articles/${section.category}/${post}`}>
                                            {post.replace(/-/g, ' ').replace('.md', '')}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))
                }
            </article >
        </div >
    );
};

export default Blogs;